import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";

export const SectionName = styled.h3`
  margin: ${spaces.smallRegular} 0;

  ${MQ("tablet")} {
    margin: ${spaces.large}) 0;
  }
`;

export const SectionWrapper = styled.section`
  > ul:not(:first-child) {
    margin: ${spaces.mini} 0;

    ${MQ("tablet")} {
      margin: ${spaces.smallRegular} 0;
    }
  }
`;

export const Link = styled.a<{ noBottomMargin: boolean }>(
  ({ noBottomMargin = false }) => css`
    &&& {
      width: 100%;
      margin: 0 auto ${noBottomMargin ? "0" : spaces.large};
    }

    ${MQ("tablet")} {
      &&& {
        width: 324px;
        margin: ${spaces.large} auto ${noBottomMargin ? "0" : spaces.big};
      }
    }
  `
);
