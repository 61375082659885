import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { GridContainer as GridContainerType } from "@xxl/content-api";
import React from "react";
import type { GridContainerProps } from "../GridContainer/GridContainer";
import GridContainer from "../GridContainer/GridContainer";
import { Link, SectionName, SectionWrapper } from "./GridSection.styled";
type GridSectionProps = {
  buttonUrl: string | null;
  gridContainers: GridContainerType[];
  name: string;
  visibleContainersCount?: number;
  buttonText?: string;
  isLastSection?: boolean;
} & Omit<GridContainerProps, "gridComponents">;

export const GridSection: React.FunctionComponent<GridSectionProps> = ({
  buttonText,
  buttonUrl,
  gridContainers,
  isLastSection = false,
  name,
  priceData,
  visibleContainersCount,
}) => {
  const visibleContainers =
    visibleContainersCount === undefined ||
    visibleContainersCount === gridContainers.length
      ? gridContainers
      : gridContainers.slice(0, visibleContainersCount);
  return (
    <SectionWrapper className="component-standard-spacing container">
      <SectionName>{name}</SectionName>
      {visibleContainers.map(
        (item, index) =>
          item.gridComponents !== undefined && (
            <GridContainer
              key={index}
              gridComponents={item.gridComponents}
              priceData={priceData}
            />
          )
      )}
      {isNotNullOrUndefined(buttonText) && isNotNullOrUndefined(buttonUrl) && (
        <Link
          noBottomMargin={isLastSection}
          href={buttonUrl}
          className="button button--primary button--large"
        >
          {buttonText}
        </Link>
      )}
    </SectionWrapper>
  );
};
