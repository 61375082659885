import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../styles/theme/colors";
import spaces from "../../styles/theme/spaces";

import { gradientImageOverlay } from "../../styled/mixins";
import { css } from "@emotion/react";

const labelText = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 20,
  lineHeight: 1.8,
  letterSpacing: -0.15,
};

const headlineText = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 26,
  lineHeight: 1,
  letterSpacing: -0.15,
  tablet: {
    fontSize: 34,
    letterSpacing: -0.3,
  },
};

const descriptionText = {
  fontFamily: "var(--font-family-regular)",
  fontSize: 15,
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

export const Wrapper = styled.div`
  height: 280px;
`;

type TextWrapperProps = {
  textAlignment: "center" | "left";
  textColor: string;
};

export const TextWrapper = styled.div<TextWrapperProps>(
  ({ textColor, textAlignment }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${textColor};
    align-items: ${textAlignment === "center" ? "center" : "flex-start"};
    justify-content: ${textAlignment === "center" ? "center" : "flex-start"};

    ${textAlignment === "left" &&
    `padding: ${spaces.smallRegular};
  
        ${MQ("tablet")} {
          padding: ${spaces.big};
        }`}

    ${textAlignment === "center" && `text-align: center;`}
  `
);

export const Label = styled.span`
  ${typographyToCss(labelText)}
  margin-bottom: ${spaces.mini};
`;

export const Headline = styled.span`
  ${typographyToCss(headlineText)}
  width: 300px;
  margin-bottom: ${spaces.smallRegular};

  ${MQ("tablet")} {
    width: 812px;
  }
`;

export const Description = styled.span`
  ${typographyToCss(descriptionText)}
  width: 300px;

  ${MQ("tablet")} {
    width: 500px;
  }
`;

type BackgroundWrapperProps = {
  imageOverlay: boolean;
  desktopImageUrl?: string;
  mobileImageUrl?: string;
  backgroundColor?: string;
};

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>(
  ({ desktopImageUrl, mobileImageUrl, backgroundColor, imageOverlay }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-image: url(${mobileImageUrl ?? ""});
    background-size: cover;
    background-color: ${backgroundColor ?? colors.xxlLightBlue};
    ${imageOverlay && gradientImageOverlay()}

    ${MQ("tablet")} {
      background-image: url(${desktopImageUrl ?? ""});
    }
  `
);

export const CountdownTimeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: ${spaces.smallRegular};
  // TODO: We should consider have the same CountdownTimer everywhere or be able to configure it
  div > p > span {
    // Timeholder+SpaceHolder
    font-size: 20px;

    ${MQ("tabletHorizontal")} {
      font-size: 24px;
    }

    &:nth-of-type(odd) > span:first-of-type {
      // Timeholder
      min-width: 26px;

      ${MQ("tabletHorizontal")} {
        min-width: 30px;
      }
    }

    &:nth-of-type(even) {
      // Spaceholder
      margin: 0 4px;

      ${MQ("tabletHorizontal")} {
        margin: 0 8px;
      }
    }
  }

  div > p > span > span:last-of-type {
    // Text
    font-family: var(--font-family-regular);
    font-size: 10px;

    ${MQ("tabletHorizontal")} {
      font-size: 15px;
    }
  }
`;
